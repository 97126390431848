import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { useFormik } from "formik";
import { getLabel } from '../../common/getLabel';
import React, { useRef, useState, useEffect } from "react";
import { isEmpty, scrollToFirstError, stateListing } from '../../../common/common';
import { baseUrl } from '../../common/config';
import { deleteProfileImage, getDataUserProfiles, setupProfileData, setupProfileEvaluationInfo, setupTrainerProfileInfo, uploadProfileCoverImage, uploadProfileImage } from '../../services/manage-user-profiles/action';
import { useDispatch } from "react-redux"
import { userProfileSchema } from '../../common/validation';
import { ImageDeleteModal } from '../../common/imageDeleteModal';
import { getUserProfileStyles } from "../../../common/common";
const AddUpdateProfile = (props) => {
    const { formshow, handleProfileViewClose, profileData, trainingStatsData, evaluationData, requestBody, editUserProfile } = props;
    const [uploadImage, setUploadImage] = useState(null);
    const [uploadedProfileImage, setUploadedProfileImage] = useState(null);
    const [uploadCoverImage, setUploadCoverImage] = useState(null);
    const [profileImage, setProfileImage] = useState((profileData && profileData.image) ? profileData.image : []);
    const [profileCoverImage, setProfileCoverImage] = useState((profileData && profileData.cover_image) ? profileData.cover_image : []);
    const [imageDeleteModalshow, setImageDeleteModalShow] = useState(false);
    const [imageDeleteId, setImageDeletedId] = useState();
    const [imageDeleteType, setImageDeleteType] = useState('');
    const handleCancelImage = (type) => {
        const setter = type === 1 ? setUploadedProfileImage : type === 2 ? setUploadCoverImage : setUploadImage;
        setter("");
    };
    const editor = useRef(null);

    const social_media = (profileData && profileData.social_media) ? JSON.parse(profileData.social_media) : '';
    const video_info = (profileData && profileData.video) ? JSON.parse(profileData.video) : '';
    const height_feet = (profileData && profileData.height_feet) ? JSON.parse(profileData.height_feet) : '';
    const height_inch = (profileData && profileData.height_inch) ? JSON.parse(profileData.height_inch) : '';
    const about = (profileData && profileData.about) ? JSON.parse(profileData.about) : '';
    const weight = (profileData && profileData.weight) ? JSON.parse(profileData.weight) : '';
    const school = (profileData && profileData.school) ? JSON.parse(profileData.school) : '';
    const coach = (profileData && profileData.coach) ? JSON.parse(profileData.coach) : '';
    const awards = (profileData && profileData.awards) ? JSON.parse(profileData.awards) : '';
    const academic_stats = (profileData && profileData.academic_stats) ? JSON.parse(profileData.academic_stats) : '';
    const sparq = (trainingStatsData && trainingStatsData.sparq) ? JSON.parse(trainingStatsData.sparq) : '';
    const camps = (trainingStatsData && trainingStatsData.camps) ? JSON.parse(trainingStatsData.camps) : '';
    const training = (trainingStatsData && trainingStatsData.training) ? JSON.parse(trainingStatsData.training) : '';
    const year_1 = (trainingStatsData && trainingStatsData.year_1) ? JSON.parse(trainingStatsData.year_1) : '';
    const year_2 = (trainingStatsData && trainingStatsData.year_2) ? JSON.parse(trainingStatsData.year_2) : '';
    const year_3 = (trainingStatsData && trainingStatsData.year_3) ? JSON.parse(trainingStatsData.year_3) : '';
    const year_4 = (trainingStatsData && trainingStatsData.year_4) ? JSON.parse(trainingStatsData.year_4) : '';
    const trainingInfo = {
        sparq_dash: (sparq && sparq.dash) ? sparq.dash : '',
        sparq_bench: (sparq && sparq.bench) ? sparq.bench : '',
        sparq_vertical: (sparq && sparq.vertical) ? sparq.vertical : '',
        sparq_broad: (sparq && sparq.broad) ? sparq.broad : '',
        sparq_cone: (sparq && sparq.cone) ? sparq.cone : '',
        year_1: year_1,
        year_2: year_2,
        year_3: year_3,
        year_4: year_4,
        camps: camps,
        training: training,
    }

    const initialValues = {
        profile_id: (profileData) ? profileData.uprofile_id : '',
        user_id: (profileData) ? profileData.uprofile_user_id : '',
        profile_type: (profileData) ? profileData.uprofile_type : '1',
        first_name: (profileData) ? profileData.uprofile_fname : '',
        last_name: (profileData) ? profileData.uprofile_lname : '',
        phone_no: (profileData) ? profileData.uprofile_phone_no : '',
        website: (profileData) ? profileData.uprofile_website : '',
        style: (profileData) ? profileData.uprofile_style : '',
        email: (profileData) ? profileData.uprofile_email : '',
        address: (profileData) ? profileData.uprofile_address : '',
        city: (profileData) ? profileData.uprofile_city : '',
        state_code: (profileData) ? profileData.uprofile_state_code : '',
        premium_override: (profileData) ? profileData.uprofile_premium_overide : '',
        zip: (profileData) ? profileData.uprofile_zip : '',
        grad_class: (profileData) ? profileData.uprofile_grad_class : '',
        height_feet: height_feet,
        height_inch: height_inch,
        social_media_facebook: (social_media && social_media.facebook) ? social_media.facebook : '',
        social_media_instagram: (social_media && social_media.instagram) ? social_media.instagram : '',
        social_media_twitter: (social_media && social_media.twitter) ? social_media.twitter : '',
        social_media_snapchat: (social_media && social_media.snapchat) ? social_media.snapchat : '',
        about: about,
        weight: weight,
        awards: awards,
        hs_name: (school && school.hs_name) ? school.hs_name : '',
        hs_city: (school && school.hs_city) ? school.hs_city : '',
        hs_state: (school && school.hs_state) ? school.hs_state : '',
        coach_name: (coach && coach.coach_name) ? coach.coach_name : '',
        coach_phone: (coach && coach.coach_phone) ? coach.coach_phone : '',
        act_score: (academic_stats && academic_stats.act_score) ? academic_stats.act_score : '',
        sat_score: (academic_stats && academic_stats.sat_score) ? academic_stats.sat_score : '',
        gpa_score: (academic_stats && academic_stats.gpa_score) ? academic_stats.gpa_score : '',

        sparq_dash: trainingInfo.sparq_dash,
        sparq_bench: trainingInfo.sparq_bench,
        sparq_vertical: trainingInfo.sparq_vertical,
        sparq_broad: trainingInfo.sparq_broad,
        sparq_cone: trainingInfo.sparq_cone,

        y1_year: trainingInfo.year_1.year,
        y2_year: trainingInfo.year_2.year,
        y3_year: trainingInfo.year_3.year,
        y4_year: trainingInfo.year_4.year,
        y1_games: trainingInfo.year_1.games,
        y1_attempt: trainingInfo.year_1.attempt,
        y1_complete: trainingInfo.year_1.complete,
        y1_yard: trainingInfo.year_1.yard,
        y1_pass_td: trainingInfo.year_1.pass_td,
        y1_rush_yd: trainingInfo.year_1.rush_yd,
        y1_rush_td: trainingInfo.year_1.rush_td,
        y2_games: trainingInfo.year_2.games,
        y2_attempt: trainingInfo.year_2.attempt,
        y2_complete: trainingInfo.year_2.complete,
        y2_yard: trainingInfo.year_2.yard,
        y2_pass_td: trainingInfo.year_2.pass_td,
        y2_rush_yd: trainingInfo.year_2.rush_yd,
        y2_rush_td: trainingInfo.year_2.rush_td,
        y3_games: trainingInfo.year_3.games,
        y3_attempt: trainingInfo.year_3.attempt,
        y3_complete: trainingInfo.year_3.complete,
        y3_yard: trainingInfo.year_3.yard,
        y3_pass_td: trainingInfo.year_3.pass_td,
        y3_rush_yd: trainingInfo.year_3.rush_yd,
        y3_rush_td: trainingInfo.year_3.rush_td,
        y4_games: trainingInfo.year_4.games,
        y4_attempt: trainingInfo.year_4.attempt,
        y4_complete: trainingInfo.year_4.complete,
        y4_yard: trainingInfo.year_4.yard,
        y4_pass_td: trainingInfo.year_4.pass_td,
        y4_rush_yd: trainingInfo.year_4.rush_yd,
        y4_rush_td: trainingInfo.year_4.rush_td,
        y1_wr_receptions: trainingInfo.year_1.wr_receptions,
        y1_wr_total_yds: trainingInfo.year_1.wr_total_yds,
        y1_wr_yards_games: trainingInfo.year_1.wr_yards_games,
        y1_wr_tds: trainingInfo.year_1.wr_tds,
        y2_wr_receptions: trainingInfo.year_2.wr_receptions,
        y2_wr_total_yds: trainingInfo.year_2.wr_total_yds,
        y2_wr_yards_games: trainingInfo.year_2.wr_yards_games,
        y2_wr_tds: trainingInfo.year_2.wr_tds,
        y3_wr_receptions: trainingInfo.year_3.wr_receptions,
        y3_wr_total_yds: trainingInfo.year_3.wr_total_yds,
        y3_wr_yards_games: trainingInfo.year_3.wr_yards_games,
        y3_wr_tds: trainingInfo.year_3.wr_tds,
        y4_wr_receptions: trainingInfo.year_4.wr_receptions,
        y4_wr_total_yds: trainingInfo.year_4.wr_total_yds,
        y4_wr_yards_games: trainingInfo.year_4.wr_yards_games,
        y4_wr_tds: trainingInfo.year_4.wr_tds,
        accuracy: (evaluationData && evaluationData.accuracy) ? evaluationData.accuracy : '',
        arm_strength: (evaluationData && evaluationData.arm_strength) ? evaluationData.arm_strength : '',
        decision_making: (evaluationData && evaluationData.decision_making) ? evaluationData.decision_making : '',
        final_rating: (evaluationData && evaluationData.final_rating) ? evaluationData.final_rating : '',
        footwork: (evaluationData && evaluationData.footwork) ? evaluationData.footwork : '',
        pocket_presence: (evaluationData && evaluationData.pocket_presence) ? evaluationData.pocket_presence : '',
        poise: (evaluationData && evaluationData.poise) ? evaluationData.poise : '',
        release: (evaluationData && evaluationData.release) ? evaluationData.release : '',
        size: (evaluationData && evaluationData.size) ? evaluationData.size : '',
        throw_on_the_run: (evaluationData && evaluationData.throw_on_the_run) ? evaluationData.throw_on_the_run : '',
        touch: (evaluationData && evaluationData.touch) ? evaluationData.touch : '',
        stars: (evaluationData && evaluationData.stars) ? evaluationData.stars : '',

        wr_te_stars: (evaluationData && evaluationData.wr_te_stars) ? evaluationData.wr_te_stars : '',
        wr_te_size: (evaluationData && evaluationData.wr_te_size) ? evaluationData.wr_te_size : '',
        wr_te_speed: (evaluationData && evaluationData.wr_te_speed) ? evaluationData.wr_te_speed : '',
        wr_te_release: (evaluationData && evaluationData.wr_te_release) ? evaluationData.wr_te_release : '',
        wr_te_catching: (evaluationData && evaluationData.wr_te_catching) ? evaluationData.wr_te_catching : '',
        wr_te_catching_traffic: (evaluationData && evaluationData.wr_te_catching_traffic) ? evaluationData.wr_te_catching_traffic : '',
        wr_te_route_short: (evaluationData && evaluationData.wr_te_route_short) ? evaluationData.wr_te_route_short : '',
        wr_te_route_med: (evaluationData && evaluationData.wr_te_route_med) ? evaluationData.wr_te_route_med : '',
        wr_te_route_deep: (evaluationData && evaluationData.wr_te_route_deep) ? evaluationData.wr_te_route_deep : '',
        wr_te_blocking: (evaluationData && evaluationData.wr_te_blocking) ? evaluationData.wr_te_blocking : '',
        wr_te_elusiveness: (evaluationData && evaluationData.wr_te_elusiveness) ? evaluationData.wr_te_elusiveness : '',
        wr_te_final: (evaluationData && evaluationData.wr_te_final) ? evaluationData.wr_te_final : '',

        scouting_report: (evaluationData && evaluationData.scouting_report) ? evaluationData.scouting_report : '',
        url_slug: '',
        video_1: '',
        video_2: '',
        video_3: '',
        video_4: '',
        video_5: '',
    };

    const videoIntialFields = { url: '' };
    const [numVideoFields, setNumVideoFields] = useState(1);
    const [videoFields, setVideoFields] = useState([videoIntialFields]);
    const addField = () => {
        setVideoFields([...videoFields, { ...videoIntialFields }]);
        setNumVideoFields(numVideoFields + 1);
    };
    const removeField = (index) => {
        const updatedFields = [...videoFields];
        updatedFields.splice(index, 1);
        setVideoFields(updatedFields);
        setNumVideoFields(index);
    };
    const handleVideoChange = (index, field, value) => {
        const updatedFields = [...videoFields];
        updatedFields[index][field] = value;
        setVideoFields(updatedFields);
    };

    useEffect(() => {
        if (video_info) {
            setVideoFields(video_info);
        }
    }, [profileData]);

    const filteredVideoField = videoFields.filter(item => item.url !== '');

    const dispatch = useDispatch();

    const [wrFinalSum, setWrFinalSum] = useState(0);
    const [evalFinalSum, setEvalFinalSum] = useState(0);

    const handleWrFinalInputChange = (e) => {
        const { value } = e.target;
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
            setWrFinalSum(parsedValue);
        }
        handleChange(e)
    };

    const handleFinalInputChange = (e) => {
        const { value } = e.target;
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
            setEvalFinalSum(parsedValue);
        }
        handleChange(e)
    };

    const [formFinalData, setFormFinalData] = useState({
        accuracy: parseFloat(evaluationData && evaluationData.accuracy) || 0,
        arm_strength: parseFloat(evaluationData && evaluationData.arm_strength) || 0,
        decision_making: parseFloat(evaluationData && evaluationData.decision_making) || 0,
        footwork: parseFloat(evaluationData && evaluationData.footwork) || 0,
        pocket_presence: parseFloat(evaluationData && evaluationData.pocket_presence) || 0,
        poise: parseFloat(evaluationData && evaluationData.poise) || 0,
        release: parseFloat(evaluationData && evaluationData.release) || 0,
        size: parseFloat(evaluationData && evaluationData.size) || 0,
        throw_on_the_run: parseFloat(evaluationData && evaluationData.throw_on_the_run) || 0,
        touch: parseFloat(evaluationData && evaluationData.touch) || 0,
    });

    const [formData, setFormData] = useState({
        wr_te_size: parseFloat(evaluationData && evaluationData.wr_te_size) || 0,
        wr_te_speed: parseFloat(evaluationData && evaluationData.wr_te_speed) || 0,
        wr_te_release: parseFloat(evaluationData && evaluationData.wr_te_release) || 0,
        wr_te_catching: parseFloat(evaluationData && evaluationData.wr_te_catching) || 0,
        wr_te_catching_traffic: parseFloat(evaluationData && evaluationData.wr_te_catching_traffic) || 0,
        wr_te_route_short: parseFloat(evaluationData && evaluationData.wr_te_route_short) || 0,
        wr_te_route_med: parseFloat(evaluationData && evaluationData.wr_te_route_med) || 0,
        wr_te_route_deep: parseFloat(evaluationData && evaluationData.wr_te_route_deep) || 0,
        wr_te_blocking: parseFloat(evaluationData && evaluationData.wr_te_blocking) || 0,
        wr_te_elusiveness: parseFloat(evaluationData && evaluationData.wr_te_elusiveness) || 0,
    });

    const handleWrInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: +value });
        handleChange(e)
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormFinalData({ ...formFinalData, [name]: +value });
        handleChange(e)
    };

    useEffect(() => {
        const calculateWrSumFinal = () => {
            let total = 0;
            Object.keys(formData).forEach(key => {
                if (myWrArray.includes(key)) {
                    total += formData[key];
                }
            });
            if (total == 0 && isEmpty((evaluationData && evaluationData.wr_te_final) ? evaluationData.wr_te_final : 0) === false) {
                setWrFinalSum((evaluationData && evaluationData.wr_te_final) ? evaluationData.wr_te_final : 0);
            } else {
                setWrFinalSum(total.toFixed(2));
            }
        };

        calculateWrSumFinal();
    }, [formData]);
    useEffect(() => {
        const calculateSumFinal = () => {
            let total = 0;
            Object.keys(formFinalData).forEach(key => {
                if (myFinalArray.includes(key)) {
                    total += formFinalData[key];
                }
            });
            if (total == 0 && isEmpty((evaluationData && evaluationData.final_rating) ? evaluationData.final_rating : 0) === false) {
                setEvalFinalSum((evaluationData && evaluationData.final_rating) ? evaluationData.final_rating : 0);
            } else {
                setEvalFinalSum(total.toFixed(2));
            }
        };

        calculateSumFinal();
    }, [formFinalData]);
    const myWrArray = [
        'wr_te_size', 'wr_te_speed', 'wr_te_release', 'wr_te_catching',
        'wr_te_catching_traffic', 'wr_te_route_short', 'wr_te_route_med',
        'wr_te_route_deep', 'wr_te_blocking', 'wr_te_elusiveness'
    ];

    const myFinalArray = ['accuracy', 'arm_strength', 'decision_making', 'footwork', 'pocket_presence', 'poise', 'release', 'size', 'throw_on_the_run', 'touch',];

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: userProfileSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            const postData = {
                profile_id: values.profile_id,
                user_id: values.user_id,
                type: initialValues.profile_type,
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                phone_no: values.phone_no,
                website: values.website,
                grad_class: values.grad_class,
                style: values.style,
                address: values.address,
                city: values.city,
                state_code: values.state_code,
                zip: values.zip,
                eval_final: evalFinalSum,
                uprofile_premium_overide: values.premium_override,
                profile_info: {
                    social_media: {
                        facebook: values.social_media_facebook,
                        snapchat: values.social_media_snapchat,
                        instagram: values.social_media_instagram,
                        twitter: values.social_media_twitter,
                    },
                    height_feet: values.height_feet,
                    height_inch: values.height_inch,
                    about: values.about,
                    weight: values.weight,
                    school: {
                        hs_name: values.hs_name,
                        hs_city: values.hs_city,
                        hs_state: values.hs_state
                    },
                    coach: {
                        coach_name: values.coach_name,
                        coach_phone: values.coach_phone
                    },
                    academic_stats: {
                        act_score: values.act_score,
                        sat_score: values.sat_score,
                        gpa_score: values.gpa_score
                    },
                    video: filteredVideoField,
                    awards: initialValues.awards,
                }
            }
            try {
                const response = await setupProfileData(postData);
                if (response.success && response.data && response.data.profile_id) {
                    const trainingInfoPostedData = {
                        profile_id: response.data.profile_id,
                        training_info:
                        {
                            year_1: {
                                yard: values.y1_yard,
                                year: values.y1_year,
                                games: values.y1_games,
                                attempt: values.y1_attempt,
                                pass_td: values.y1_pass_td,
                                rush_td: values.y1_rush_td,
                                rush_yd: values.y1_rush_yd,
                                complete: values.y1_complete,
                                wr_receptions: values.y1_wr_receptions,
                                wr_total_yds: values.y1_wr_total_yds,
                                wr_yards_games: values.y1_wr_yards_games,
                                wr_tds: values.y1_wr_tds
                            },
                            year_2: {
                                yard: values.y2_yard,
                                year: values.y2_year,
                                games: values.y2_games,
                                attempt: values.y2_attempt,
                                pass_td: values.y2_pass_td,
                                rush_td: values.y2_rush_td,
                                rush_yd: values.y2_rush_yd,
                                complete: values.y2_complete,
                                wr_receptions: values.y2_wr_receptions,
                                wr_total_yds: values.y2_wr_total_yds,
                                wr_yards_games: values.y2_wr_yards_games,
                                wr_tds: values.y2_wr_tds
                            },
                            year_3: {
                                yard: values.y3_yard,
                                year: values.y3_year,
                                games: values.y3_games,
                                attempt: values.y3_attempt,
                                pass_td: values.y3_pass_td,
                                rush_td: values.y3_rush_td,
                                rush_yd: values.y3_rush_yd,
                                complete: values.y3_complete,
                                wr_receptions: values.y3_wr_receptions,
                                wr_total_yds: values.y3_wr_total_yds,
                                wr_yards_games: values.y3_wr_yards_games,
                                wr_tds: values.y3_wr_tds
                            },
                            year_4: {
                                yard: values.y4_yard,
                                year: values.y4_year,
                                games: values.y4_games,
                                attempt: values.y4_attempt,
                                pass_td: values.y4_pass_td,
                                rush_td: values.y4_rush_td,
                                rush_yd: values.y4_rush_yd,
                                complete: values.y4_complete,
                                wr_receptions: values.y4_wr_receptions,
                                wr_total_yds: values.y4_wr_total_yds,
                                wr_yards_games: values.y4_wr_yards_games,
                                wr_tds: values.y4_wr_tds
                            },
                            sparq: {
                                dash: values.sparq_dash,
                                bench: values.sparq_bench,
                                vertical: values.sparq_vertical,
                                broad: values.sparq_broad,
                                cone: values.sparq_cone
                            },
                            training: trainingInfo.training,
                            camps: trainingInfo.camps,
                        }
                    }
                    await setupTrainerProfileInfo(trainingInfoPostedData);
                    const evaluationInfoPostedData = {
                        profile_id: response.data.profile_id,
                        evaluation_info:
                        {
                            stars: values.stars,
                            size: values.size,
                            accuracy: values.accuracy,
                            arm_strength: values.arm_strength,
                            release: values.release,
                            throw_on_the_run: values.throw_on_the_run,
                            footwork: values.footwork,
                            poise: values.poise,
                            pocket_presence: values.pocket_presence,
                            decision_making: values.decision_making,
                            touch: values.touch,
                            final_rating: evalFinalSum,
                            wr_te_stars: values.wr_te_stars,
                            wr_te_size: values.wr_te_size,
                            wr_te_speed: values.wr_te_speed,
                            wr_te_release: values.wr_te_release,
                            wr_te_catching: values.wr_te_catching,
                            wr_te_catching_traffic: values.wr_te_catching_traffic,
                            wr_te_route_short: values.wr_te_route_short,
                            wr_te_route_med: values.wr_te_route_med,
                            wr_te_route_deep: values.wr_te_route_deep,
                            wr_te_blocking: values.wr_te_blocking,
                            wr_te_elusiveness: values.wr_te_elusiveness,
                            wr_te_final: wrFinalSum,
                            scouting_report: values.scouting_report,
                        }
                    }
                    await setupProfileEvaluationInfo(evaluationInfoPostedData);

                    if (uploadedProfileImage) {
                        const formData = new FormData();
                        formData.append("profile_id", response.data.profile_id);
                        formData.append("profile_image", uploadedProfileImage);
                        const profileImageResponse = await uploadProfileImage(formData);
                        if (profileImageResponse.success) {
                            setProfileImage(response.data);
                        }
                    }
                    if (uploadCoverImage) {
                        const formData = new FormData();
                        formData.append("profile_id", response.data.profile_id);
                        formData.append("profile_image", uploadCoverImage);
                        const coverImageResponse = await uploadProfileCoverImage(formData);
                        if (coverImageResponse.success) {
                            setProfileCoverImage(response.data);
                        }
                    }
                }
                handleProfileViewClose();
                dispatch(getDataUserProfiles(requestBody));
            } catch (error) {
                console.log(error);
            }
        },
    });
    const handleChange = (e) => {
        formik.handleChange(e);
    };

    const handleImageDeleteModalShow = (id, type) => {
        setImageDeletedId(id);
        setImageDeleteType(type);
        setImageDeleteModalShow(true);
    };
    const handleDeleteImage = async () => {
        try {
            const data = { profile_id: imageDeleteId, image_type: imageDeleteType };
            await deleteProfileImage(data);
            handleImageDeleteModalClose();
            const Name = "image_list_" + imageDeleteType;
            document.querySelector("." + Name).remove();
        } catch (error) { }
    };

    const handleImageDeleteModalClose = () => setImageDeleteModalShow(false);

    const fieldRefs = useRef({});
    useEffect(() => {
        scrollToFirstError(formik, fieldRefs);
    }, [formik.errors, formik.touched, formik.isSubmitting]);

    return (
        <>
            <Modal
                show={formshow}
                onHide={handleProfileViewClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">{getLabel("LBL_UPDATE_AN_ENTRY_IN_PROFILES")}</h5>
                </Modal.Header>

                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h4>{getLabel('LBL_Player_information')}</h4>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_FIRST*")}</label>
                                            <input className="form-control" type="text" id="first_name" disabled={!editUserProfile} ref={el => fieldRefs.current.first_name = el} name="first_name" value={formik.values.first_name} onChange={handleChange} />
                                            <div>
                                                {formik.touched.first_name && formik.errors.first_name && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.first_name}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_LAST*")}</label>
                                            <input className="form-control" type="text" id="last_name" disabled={!editUserProfile} ref={el => fieldRefs.current.last_name = el} name="last_name" value={formik.values.last_name} onChange={handleChange} />
                                            <div>
                                                {formik.touched.last_name && formik.errors.last_name && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.last_name}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="from-group">
                                            <label htmlFor="input">{getLabel("LBL_PREMIUM")}</label>
                                            <select as="select" className="form-select" name="premium_override" disabled={!editUserProfile} value={formik.values.premium_override} onChange={handleChange} aria-label="Default select example">
                                                <option value="">{getLabel('LBL_Select_One')}</option>
                                                <option value="1">ON</option>
                                                <option value="0">OFF</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_PLAYER_ADDRESS")}</label>
                                            <input className="form-control" type="text" id="address" disabled={!editUserProfile} name="address" ref={el => fieldRefs.current.address = el} value={formik.values.address} onChange={handleChange} />
                                            <div>
                                                {formik.touched.address && formik.errors.address && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.address}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_PLAYER_CITY")}</label>
                                            <input className="form-control" type="text" id="city" name="city" disabled={!editUserProfile} ref={el => fieldRefs.current.city = el} value={formik.values.city} onChange={handleChange} />
                                            <div>
                                                {formik.touched.city && formik.errors.city && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.city}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_PLAYER_STATE")}</label>
                                            <select as="select" className="form-select" name="state_code" disabled={!editUserProfile} ref={el => fieldRefs.current.state_code = el} value={formik.values.state_code} aria-label="Default select example" onChange={handleChange}>
                                                <option value="">{getLabel('LBL_*State')}</option>
                                                {stateListing && stateListing().map((state, index) => (
                                                    <option key={index} value={state}>{state}</option>
                                                ))}
                                            </select>
                                            <div>
                                                {formik.touched.state_code && formik.errors.state_code && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.state_code}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_PLAYER_ZIP")}</label>
                                            <input className="form-control" type="text" id="zip" name="zip" disabled={!editUserProfile} ref={el => fieldRefs.current.zip = el} value={formik.values.zip} onChange={handleChange} />
                                            <div>
                                                {formik.touched.zip && formik.errors.zip && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.zip}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <label className="custom">{getLabel("LBL_PROFILE_IMAGE")}</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="profile_logo"
                                            onChange={(e) => setUploadedProfileImage(e.target.files[0])}
                                            disabled={!editUserProfile}
                                        />
                                        <div className="main-div">
                                            {uploadedProfileImage ? (
                                                <img
                                                    src={URL.createObjectURL(uploadedProfileImage)}
                                                    style={{
                                                        height: "100px",
                                                        width: "100px",
                                                        borderRadius: "50%",
                                                    }}
                                                    onClick={() => handleCancelImage(1)}
                                                    alt="image-path"
                                                />
                                            ) : (
                                                <div className="show--image">
                                                    <div className={`image_list_1`}>
                                                        {profileImage && profileImage.length > 0 && (
                                                            <div>
                                                                <img
                                                                    src={`${baseUrl}/${profileImage[0].afile_physical_path}`}
                                                                    style={{
                                                                        height: "100px",
                                                                        width: "100px",
                                                                        borderRadius: "50%",
                                                                    }}
                                                                    alt="image-path"
                                                                />
                                                                {editUserProfile && (
                                                                    <input
                                                                        type="button"
                                                                        value="x"
                                                                        className="btn-rmv1 rmv"
                                                                        onClick={() => handleImageDeleteModalShow(formik.values.profile_id, 1)}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <label className="custom">{getLabel("LBL_COVER_IMAGE")}</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="profile_logo"
                                            onChange={(e) => setUploadCoverImage(e.target.files[0])}
                                            disabled={!editUserProfile}
                                        />
                                        <div className="main-div">
                                            {uploadCoverImage ? (
                                                <img
                                                    src={URL.createObjectURL(uploadCoverImage)}
                                                    style={{
                                                        height: "100px",
                                                        width: "100px",
                                                        borderRadius: "50%",
                                                    }}
                                                    onClick={() => handleCancelImage(2)}
                                                    alt="image-path"
                                                />
                                            ) : (
                                                <div className="show--image">
                                                    <div className={`image_list_2`}>
                                                        {profileCoverImage && profileCoverImage.length > 0 && (
                                                            <div>
                                                                <img
                                                                    src={`${baseUrl}/${profileCoverImage[0].afile_physical_path}`}
                                                                    style={{
                                                                        height: "100px",
                                                                        width: "100px",
                                                                        borderRadius: "50%",
                                                                    }}
                                                                    alt="image-path"
                                                                />
                                                                {editUserProfile && (
                                                                    <input
                                                                        type="button"
                                                                        value="x"
                                                                        className="btn-rmv1 rmv"
                                                                        onClick={() => handleImageDeleteModalShow(formik.values.profile_id, 2)}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_PLAYER_EMAIL")}</label>
                                            <input className="form-control" type="text" id="email" name="email" disabled={!editUserProfile} ref={el => fieldRefs.current.email = el} value={formik.values.email} onChange={handleChange} />
                                        </div>
                                        <div>
                                            {formik.touched.email && formik.errors.email && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.email}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_PLAYER_PHONE")}</label>
                                            <input className="form-control" type="text" id="phone_no" name="phone_no" disabled={!editUserProfile} value={formik.values.phone_no} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="from-group">
                                            <label htmlFor="input">{getLabel("LBL_HT_FEET")}</label>
                                            <select as="select" className="form-select" name="height_feet" disabled={!editUserProfile} value={formik.values.height_feet} onChange={handleChange} aria-label="Default select example">
                                                <option value="">{getLabel('LBL_-Feet-')}</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="from-group">
                                            <label htmlFor="input">{getLabel("LBL_HT_INCH")}</label>
                                            <select as="select" className="form-select" name="height_inch" disabled={!editUserProfile} value={formik.values.height_inch} onChange={handleChange} aria-label="Default select example">
                                                <option value="">{getLabel('LBL_-Inches-')}</option>
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_WT")}</label>
                                            <input className="form-control" type="text" id="weight" name="weight" disabled={!editUserProfile} value={formik.values.weight} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <h4>{getLabel('LBL_Coach_information')}</h4>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_COACH_NAME")}</label>
                                            <input className="form-control" type="text" id="coach_name" name="coach_name" disabled={!editUserProfile} value={formik.values.coach_name} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_COACH_PHONE")}</label>
                                            <input className="form-control" type="text" id="coach_phone" name="coach_phone" disabled={!editUserProfile} value={formik.values.coach_phone} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <h4>{getLabel('LBL_School_information')}</h4>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_HS_NAME*")}</label>
                                            <input className="form-control" type="text" id="hs_name" name="hs_name" disabled={!editUserProfile} ref={el => fieldRefs.current.hs_name = el} value={formik.values.hs_name} onChange={handleChange} />
                                        </div>
                                        <div>
                                            {formik.touched.hs_name && formik.errors.hs_name && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.hs_name}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_HS_STATE*")}</label>
                                            <select as="select" name="hs_state" value={formik.values.hs_state} disabled={!editUserProfile} ref={el => fieldRefs.current.hs_state = el} onChange={handleChange} className="form-select" aria-label="Default select example">
                                                <option value="">{getLabel('LBL_*State')}</option>
                                                {stateListing && stateListing().map((state, index) => (
                                                    <option key={index} value={state}>{state}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            {formik.touched.hs_state && formik.errors.hs_state && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.hs_state}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_HS_CITY*")}</label>
                                            <input className="form-control" type="text" id="hs_city" name="hs_city" disabled={!editUserProfile} ref={el => fieldRefs.current.hs_city = el} value={formik.values.hs_city} onChange={handleChange} />
                                        </div>
                                        <div>
                                            {formik.touched.hs_city && formik.errors.hs_city && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.hs_city}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_CLASS*")}</label>
                                            <select as="select" name="grad_class" value={formik.values.grad_class} disabled={!editUserProfile} className="form-select" ref={el => fieldRefs.current.grad_class = el} aria-label="Default select example" onChange={handleChange}>
                                                <option value="">{getLabel('LBL_Class*')}</option>
                                                <option value="2024">Class of 2024</option>
                                                <option value="2025">Class of 2025</option>
                                                <option value="2026">Class of 2026</option>
                                                <option value="2027">Class of 2027</option>
                                                <option value="2028">Class of 2028</option>
                                                <option value="2029">Class of 2029</option>
                                                <option value="2030">Class of 2030</option>
                                                <option value="2031">Class of 2031</option>
                                                <option value="2032">Class of 2032</option>
                                            </select>
                                        </div>
                                        <div>
                                            {formik.touched.grad_class && formik.errors.grad_class && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.grad_class}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <h4>{getLabel('LBL_Position')}</h4>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="from-group">
                                            <label htmlFor="input">{getLabel("LBL_STYLE*")}</label>
                                            <select as="select" name="style" value={formik.values.style} className="form-select" disabled={!editUserProfile} ref={el => fieldRefs.current.style = el} aria-label="Default select example" onChange={handleChange}>
                                                <option value="">{getLabel('LBL_Style*')}</option>
                                                {Object.keys(getUserProfileStyles).map((key) => (
                                                    <option key={key} value={key}>
                                                        {getUserProfileStyles[key]}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {formik.touched.style && formik.errors.style && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.style}</span>
                                        )}
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <h4>{getLabel('LBL_Personal_statement')}</h4>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_STATEMENT")}</label>
                                            <textarea className="form-control" id="about" name="about" rows="3" disabled={!editUserProfile} value={formik.values.about} onChange={handleChange} ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <label htmlFor="input">{getLabel("LBL_VIDEO")}</label>
                                    </div>
                                    {videoFields && videoFields.map((field, index) => (
                                        <React.Fragment key={index}>
                                            <div className="col-lg-11 mt-3">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" name={`url[${index}]`} value={field.url} onChange={(e) => handleVideoChange(index, 'url', e.target.value)} />
                                                </div>
                                            </div>
                                            {editUserProfile && index > 0 && (
                                                <div className="col-lg-1 required_inp">
                                                    <div className="from-group">
                                                        <input type="button" className="inputRemove" value="X" onClick={() => removeField(index)}></input>
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    {editUserProfile && numVideoFields <= 4 && (
                                        <div className="col-lg-12 my-3">
                                            <button type="button" className="add_input" onClick={() => addField()}><i className='bx bx-plus'></i> {getLabel('LBL_Add_Video')}</button>
                                        </div>
                                    )}
                                    <div className="col-lg-12 mt-4">
                                        <h4>{getLabel('LBL_Social_accounts')}</h4>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_TWITTER")}</label>
                                            <input className="form-control" type="text" id="social_media_twitter" name="social_media_twitter" disabled={!editUserProfile} value={formik.values.social_media_twitter} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_INSTAGRAM")}</label>
                                            <input className="form-control" type="text" id="social_media_instagram" name="social_media_instagram" disabled={!editUserProfile} value={formik.values.social_media_instagram} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_SNAPCHAT")}</label>
                                            <input className="form-control" type="text" id="social_media_snapchat" name="social_media_snapchat" disabled={!editUserProfile} value={formik.values.social_media_snapchat} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_FACEBOOK")}</label>
                                            <input className="form-control" type="text" id="social_media_facebook" name="social_media_facebook" disabled={!editUserProfile} value={formik.values.social_media_facebook} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <h4>{getLabel('LBL_Academic_stats')}</h4>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_ACT")}</label>
                                            <input className="form-control" type="text" id="act_score" name="act_score" disabled={!editUserProfile} value={formik.values.act_score} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_SAT")}</label>
                                            <input className="form-control" type="text" id="sat_score" name="sat_score" disabled={!editUserProfile} value={formik.values.sat_score} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_GPA")}</label>
                                            <input className="form-control" type="text" id="gpa_score" name="gpa_score" disabled={!editUserProfile} value={formik.values.gpa_score} onChange={handleChange} />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 mt-4">
                                        <h4>{getLabel('LBL_Training_and_Stats')}</h4>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y1_YEAR")}</label>
                                                            <input className="form-control" type="text" id="y1_year" name="y1_year" disabled={!editUserProfile} value={formik.values.y1_year} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y1_GAMES")}</label>
                                                            <input className="form-control" type="text" id="y1_games" name="y1_games" disabled={!editUserProfile} value={formik.values.y1_games} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y1_ATTEMPT")}</label>
                                                            <input className="form-control" type="text" id="y1_attempt" name="y1_attempt" disabled={!editUserProfile} value={formik.values.y1_attempt} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y1_PASS_COMPLETE")}</label>
                                                            <input className="form-control" type="text" id="y1_complete" name="y1_complete" disabled={!editUserProfile} value={formik.values.y1_complete} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y1_PASS_YARD")}</label>
                                                            <input className="form-control" type="text" id="y1_yard" name="y1_yard" disabled={!editUserProfile} value={formik.values.y1_yard} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y1_PASS_TD")}</label>
                                                            <input className="form-control" type="text" id="y1_pass_td" name="y1_pass_td" disabled={!editUserProfile} value={formik.values.y1_pass_td} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y1_RUSH_YD")}</label>
                                                            <input className="form-control" type="text" id="y1_rush_yd" name="y1_rush_yd" disabled={!editUserProfile} value={formik.values.y1_rush_yd} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y1_RUSH_TD")}</label>
                                                            <input className="form-control" type="text" id="y1_rush_td" name="y1_rush_td" disabled={!editUserProfile} value={formik.values.y1_rush_td} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y1_Receptions(wr)")}</label>
                                                            <input className="form-control" type="text" id="y1_wr_receptions" name="y1_wr_receptions" disabled={!editUserProfile} value={formik.values.y1_wr_receptions} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y1_Total_yds(wr)")}</label>
                                                            <input className="form-control" type="text" id="y1_wr_total_yds" name="y1_wr_total_yds" disabled={!editUserProfile} value={formik.values.y1_wr_total_yds} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y1_Yards/game(wr)")}</label>
                                                            <input className="form-control" type="text" id="y1_wr_yards_games" name="y1_wr_yards_games" disabled={!editUserProfile} value={formik.values.y1_wr_yards_games} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y1_Tds(wr)")}</label>
                                                            <input className="form-control" type="text" id="y1_wr_tds" name="y1_wr_tds" disabled={!editUserProfile} value={formik.values.y1_wr_tds} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y2_YEAR")}</label>
                                                            <input className="form-control" type="text" id="y2_year" name="y2_year" disabled={!editUserProfile} value={formik.values.y2_year} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y2_GAMES")}</label>
                                                            <input className="form-control" type="text" id="y2_games" name="y2_games" disabled={!editUserProfile} value={formik.values.y2_games} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y2_ATTEMPT")}</label>
                                                            <input className="form-control" type="text" id="y2_attempt" name="y2_attempt" disabled={!editUserProfile} value={formik.values.y2_attempt} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y2_PASS_COMPLETE")}</label>
                                                            <input className="form-control" type="text" id="y2_complete" name="y2_complete" disabled={!editUserProfile} value={formik.values.y2_complete} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y2_PASS_YARD")}</label>
                                                            <input className="form-control" type="text" id="y2_yard" name="y2_yard" disabled={!editUserProfile} value={formik.values.y2_yard} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y2_PASS_TD")}</label>
                                                            <input className="form-control" type="text" id="y2_pass_td" name="y2_pass_td" disabled={!editUserProfile} value={formik.values.y2_pass_td} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y2_RUSH_YD")}</label>
                                                            <input className="form-control" type="text" id="y2_rush_yd" name="y2_rush_yd" disabled={!editUserProfile} value={formik.values.y2_rush_yd} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y2_RUSH_TD")}</label>
                                                            <input className="form-control" type="text" id="y2_rush_td" name="y2_rush_td" disabled={!editUserProfile} value={formik.values.y2_rush_td} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y2_Receptions(wr)")}</label>
                                                            <input className="form-control" type="text" id="y2_wr_receptions" name="y2_wr_receptions" disabled={!editUserProfile} value={formik.values.y2_wr_receptions} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y2_Total_yds(wr)")}</label>
                                                            <input className="form-control" type="text" id="y2_wr_total_yds" name="y2_wr_total_yds" disabled={!editUserProfile} value={formik.values.y2_wr_total_yds} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y2_Yards/game(wr)")}</label>
                                                            <input className="form-control" type="text" id="y2_wr_yards_games" name="y2_wr_yards_games" disabled={!editUserProfile} value={formik.values.y2_wr_yards_games} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y2_Tds(wr)")}</label>
                                                            <input className="form-control" type="text" id="y2_wr_tds" name="y2_wr_tds" disabled={!editUserProfile} value={formik.values.y2_wr_tds} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y3_YEAR")}</label>
                                                            <input className="form-control" type="text" id="y3_year" name="y3_year" disabled={!editUserProfile} value={formik.values.y3_year} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y3_GAMES")}</label>
                                                            <input className="form-control" type="text" id="y3_games" name="y3_games" disabled={!editUserProfile} value={formik.values.y3_games} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y3_ATTEMPT")}</label>
                                                            <input className="form-control" type="text" id="y3_attempt" name="y3_attempt" disabled={!editUserProfile} value={formik.values.y3_attempt} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y3_PASS_COMPLETE")}</label>
                                                            <input className="form-control" type="text" id="y3_complete" name="y3_complete" disabled={!editUserProfile} value={formik.values.y3_complete} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y3_PASS_YARD")}</label>
                                                            <input className="form-control" type="text" id="y3_yard" name="y3_yard" disabled={!editUserProfile} value={formik.values.y3_yard} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y3_PASS_TD")}</label>
                                                            <input className="form-control" type="text" id="y3_pass_td" name="y3_pass_td" disabled={!editUserProfile} value={formik.values.y3_pass_td} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y3_RUSH_YD")}</label>
                                                            <input className="form-control" type="text" id="y3_rush_yd" name="y3_rush_yd" disabled={!editUserProfile} value={formik.values.y3_rush_yd} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y3_RUSH_TD")}</label>
                                                            <input className="form-control" type="text" id="y3_rush_td" name="y3_rush_td" disabled={!editUserProfile} value={formik.values.y3_rush_td} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y3_Receptions(wr)")}</label>
                                                            <input className="form-control" type="text" id="y3_wr_receptions" name="y3_wr_receptions" disabled={!editUserProfile} value={formik.values.y3_wr_receptions} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y3_Total_yds(wr)")}</label>
                                                            <input className="form-control" type="text" id="y3_wr_total_yds" name="y3_wr_total_yds" disabled={!editUserProfile} value={formik.values.y3_wr_total_yds} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y3_Yards/game(wr)")}</label>
                                                            <input className="form-control" type="text" id="y3_wr_yards_games" name="y3_wr_yards_games" disabled={!editUserProfile} value={formik.values.y3_wr_yards_games} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y3_Tds(wr)")}</label>
                                                            <input className="form-control" type="text" id="y3_wr_tds" name="y3_wr_tds" disabled={!editUserProfile} value={formik.values.y3_wr_tds} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y4_YEAR")}</label>
                                                            <input className="form-control" type="text" id="y4_year" name="y4_year" disabled={!editUserProfile} value={formik.values.y4_year} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y4_GAMES")}</label>
                                                            <input className="form-control" type="text" id="y4_games" name="y4_games" disabled={!editUserProfile} value={formik.values.y4_games} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y4_ATTEMPT")}</label>
                                                            <input className="form-control" type="text" id="y4_attempt" name="y4_attempt" disabled={!editUserProfile} value={formik.values.y4_attempt} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y4_PASS_COMPLETE")}</label>
                                                            <input className="form-control" type="text" id="y4_complete" name="y4_complete" disabled={!editUserProfile} value={formik.values.y4_complete} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y4_PASS_YARD")}</label>
                                                            <input className="form-control" type="text" id="y4_yard" name="y4_yard" disabled={!editUserProfile} value={formik.values.y4_yard} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y4_PASS_TD")}</label>
                                                            <input className="form-control" type="text" id="y4_pass_td" name="y4_pass_td" disabled={!editUserProfile} value={formik.values.y4_pass_td} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y4_RUSH_YD")}</label>
                                                            <input className="form-control" type="text" id="y4_rush_yd" name="y4_rush_yd" disabled={!editUserProfile} value={formik.values.y4_rush_yd} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y4_RUSH_TD")}</label>
                                                            <input className="form-control" type="text" id="y4_rush_td" name="y4_rush_td" disabled={!editUserProfile} value={formik.values.y4_rush_td} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y4_Receptions(wr)")}</label>
                                                            <input className="form-control" type="text" id="y4_wr_receptions" name="y4_wr_receptions" disabled={!editUserProfile} value={formik.values.y4_wr_receptions} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y4_Total_yds(wr)")}</label>
                                                            <input className="form-control" type="text" id="y4_wr_total_yds" name="y4_wr_total_yds" disabled={!editUserProfile} value={formik.values.y4_wr_total_yds} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y4_Yards/game(wr)")}</label>
                                                            <input className="form-control" type="text" id="y4_wr_yards_games" name="y4_wr_yards_games" disabled={!editUserProfile} value={formik.values.y4_wr_yards_games} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="input">{getLabel("LBL_Y4_Tds(wr)")}</label>
                                                            <input className="form-control" type="text" id="y4_wr_tds" name="y4_wr_tds" disabled={!editUserProfile} value={formik.values.y4_wr_tds} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 mt-4">
                                        <h4>{getLabel('LBL_Sparq')}</h4>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_DASH")}</label>
                                            <input className="form-control" type="text" id="sparq_dash" name="sparq_dash" disabled={!editUserProfile} value={formik.values.sparq_dash} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_BENCH")}</label>
                                            <input className="form-control" type="text" id="sparq_bench" name="sparq_bench" disabled={!editUserProfile} value={formik.values.sparq_bench} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_VERTICAL")}</label>
                                            <input className="form-control" type="text" id="sparq_vertical" name="sparq_vertical" disabled={!editUserProfile} value={formik.values.sparq_vertical} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_BROAD")}</label>
                                            <input className="form-control" type="text" id="sparq_broad" name="sparq_broad" disabled={!editUserProfile} value={formik.values.sparq_broad} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_CONE")}</label>
                                            <input className="form-control" type="text" id="sparq_cone" name="sparq_cone" disabled={!editUserProfile} value={formik.values.sparq_cone} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4"></div>
                                    <div className="col-lg-12 mt-4">
                                        <h4>{getLabel('LBL_Evaluation')}</h4>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="from-group">
                                            <label htmlFor="input">{getLabel("LBL_STARS")}</label>
                                            <select as="select" className="form-select" name="stars" disabled={!editUserProfile} value={formik.values.stars} onChange={handleChange} aria-label="Default select example">
                                                <option value="">{getLabel('LBL_Select_One')}</option>
                                                <option value="1">1</option>
                                                <option value="1.5">1.5</option>
                                                <option value="2">2</option>
                                                <option value="2.5">2.5</option>
                                                <option value="3">3</option>
                                                <option value="3.5">3.5</option>
                                                <option value="4">4</option>
                                                <option value="4.5">4.5</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_SIZE")}</label>
                                            <input className="form-control" type="text" id="size" name="size" disabled={!editUserProfile} value={formik.values.size} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_ACCURACY")}</label>
                                            <input className="form-control" type="text" id="accuracy" name="accuracy" disabled={!editUserProfile} value={formik.values.accuracy} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_ARM_STRENGTH")}</label>
                                            <input className="form-control" type="text" id="arm_strength" name="arm_strength" disabled={!editUserProfile} value={formik.values.arm_strength} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_RELEASE")}</label>
                                            <input className="form-control" type="text" id="release" name="release" disabled={!editUserProfile} value={formik.values.release} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_THROW_ON_RUN")}</label>
                                            <input className="form-control" type="text" id="throw_on_the_run" name="throw_on_the_run" disabled={!editUserProfile} value={formik.values.throw_on_the_run} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_FOOTWORK")}</label>
                                            <input className="form-control" type="text" id="footwork" name="footwork" disabled={!editUserProfile} value={formik.values.footwork} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_POISE")}</label>
                                            <input className="form-control" type="text" id="poise" name="poise" disabled={!editUserProfile} value={formik.values.poise} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_POCKET_PRESENCE")}</label>
                                            <input className="form-control" type="text" id="pocket_presence" name="pocket_presence" disabled={!editUserProfile} value={formik.values.pocket_presence} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_DECISION_MAKING")}</label>
                                            <input className="form-control" type="text" id="decision_making" name="decision_making" disabled={!editUserProfile} value={formik.values.decision_making} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_TOUCH")}</label>
                                            <input className="form-control" type="text" id="touch" name="touch" disabled={!editUserProfile} value={formik.values.touch} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_FINAL")}</label>
                                            <input className="form-control" type="text" id="final_rating" name="final_rating" disabled={!editUserProfile} value={evalFinalSum} onChange={handleFinalInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="from-group">
                                            <label htmlFor="input">{getLabel("LBL_WR/TE_STARS")}</label>
                                            <select as="select" className="form-select" name="wr_te_stars" disabled={!editUserProfile} value={formik.values.wr_te_stars} onChange={handleChange} aria-label="Default select example">
                                                <option value="">{getLabel('LBL_Select_One')}</option>
                                                <option value="1">1</option>
                                                <option value="1.5">1.5</option>
                                                <option value="2">2</option>
                                                <option value="2.5">2.5</option>
                                                <option value="3">3</option>
                                                <option value="3.5">3.5</option>
                                                <option value="4">4</option>
                                                <option value="4.5">4.5</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_WR/TE_SIZE")}</label>
                                            <input className="form-control" type="text" id="wr_te_size" name="wr_te_size" disabled={!editUserProfile} value={formik.values.wr_te_size} onChange={handleWrInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_WR/TE_SPEED")}</label>
                                            <input className="form-control" type="text" id="wr_te_speed" name="wr_te_speed" disabled={!editUserProfile} value={formik.values.wr_te_speed} onChange={handleWrInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_WR/TE_RELEASE")}</label>
                                            <input className="form-control" type="text" id="wr_te_release" name="wr_te_release" disabled={!editUserProfile} value={formik.values.wr_te_release} onChange={handleWrInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_CATCHING")}</label>
                                            <input className="form-control" type="text" id="wr_te_catching" name="wr_te_catching" disabled={!editUserProfile} value={formik.values.wr_te_catching} onChange={handleWrInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_CATCHING_IN_TRAFFIC")}</label>
                                            <input className="form-control" type="text" id="wr_te_catching_traffic" name="wr_te_catching_traffic" disabled={!editUserProfile} value={formik.values.wr_te_catching_traffic} onChange={handleWrInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_ROUTE_RUNNING/SHORT")}</label>
                                            <input className="form-control" type="text" id="wr_te_route_short" name="wr_te_route_short" disabled={!editUserProfile} value={formik.values.wr_te_route_short} onChange={handleWrInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_ROUTE_RUNNING/MED")}</label>
                                            <input className="form-control" type="text" id="wr_te_route_med" name="wr_te_route_med" disabled={!editUserProfile} value={formik.values.wr_te_route_med} onChange={handleWrInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_ROUTE_RUNNING/DEEP")}</label>
                                            <input className="form-control" type="text" id="wr_te_route_deep" name="wr_te_route_deep" disabled={!editUserProfile} value={formik.values.wr_te_route_deep} onChange={handleWrInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_BLOCKING")}</label>
                                            <input className="form-control" type="text" id="wr_te_blocking" name="wr_te_blocking" disabled={!editUserProfile} value={formik.values.wr_te_blocking} onChange={handleWrInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_ELUSIVENESS")}</label>
                                            <input className="form-control" type="text" id="wr_te_elusiveness" name="wr_te_elusiveness" disabled={!editUserProfile} value={formik.values.wr_te_elusiveness} onChange={handleWrInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_WR/TE_FINAL")}</label>
                                            <input className="form-control" type="text" id="wr_te_final" name="wr_te_final" disabled={!editUserProfile} value={wrFinalSum} onChange={handleWrFinalInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_SCOUT_REPORT")}</label>
                                            <textarea className="form-control" id="scouting_report" name="scouting_report" rows="3" disabled={!editUserProfile} value={formik.values.scouting_report} onChange={handleWrInputChange} ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {editUserProfile && (
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleProfileViewClose}>
                                {getLabel("LBL_Close")}
                            </Button>
                            <Button variant="primary" type="submit">{getLabel("LBL_Submit")}</Button>
                        </Modal.Footer>
                    )}
                </form>
            </Modal>
            <ImageDeleteModal
                imageDeleteModalshow={imageDeleteModalshow}
                handleImageDeleteModalClose={handleImageDeleteModalClose}
                handleDeleteImage={handleDeleteImage}
            />
        </>
    )
}

export default AddUpdateProfile
